define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "caluma": {
      "analytics": {
        "back": "Zurück zur Liste",
        "cancel": "Abbrechen",
        "edit": {
          "add-field": "Feld hinzufügen",
          "add-filters": "Filter hinzufügen",
          "aggregation": "Aggregation",
          "delete-field": "Feld löschen",
          "display-title": "Anzeigename",
          "display-title-placeholder": "Anzeigename...",
          "edit-filters": "{num} Filter bearbeiten",
          "empty": "Keine erfassten Felder",
          "field": "Feld",
          "filter": "Filter",
          "question": "Frage",
          "show-in-output": "In Ausgabe anzeigen"
        },
        "filter-modal": {
          "delete-filter": "Filter löschen",
          "empty": "Keine erfassten Filter",
          "filter-placeholder": "Filter...",
          "filters": "Filter"
        },
        "list": {
          "edit": "Bearbeiten",
          "list-title": "Liste aller Auswertungen"
        },
        "notification": {
          "alias-exists": "Alias existiert bereits!",
          "create-error": "Beim Erstellen ist ein Fehler aufgetreten.",
          "delete-error": "Beim Löschen ist ein Fehler aufgetreten.",
          "fetch-error": "Beim Laden der Daten ist ein Fehler aufgetreten.",
          "field-invalid": "Die Angaben zum Feld sind fehlerhaft.",
          "field-saved": "Feld gespeichert.",
          "filter-exists": "Filter existiert bereits!!",
          "reorder-error": "Beim Sortieren der Felder ist etwas schief gelaufen.",
          "reorder-success": "Felder wurden erfolgreich sortiert!"
        },
        "preview": {
          "export": "Export"
        },
        "report": {
          "delete": "Auswertung löschen",
          "new": "Neue Auswertung anlegen",
          "starting-object": "Ausgangspunkt",
          "title": "Auswertungsname"
        },
        "save": "Speichern",
        "sections": {
          "create-report": "Auswertung erstellen",
          "fields": "Felder",
          "show-report": "Auswertung {slug}",
          "table": "Resultate"
        },
        "starting-options": {
          "cases": "Dossiers",
          "documents": "Dokumente",
          "work-items": "Aufgaben"
        }
      },
      "caluma-query": {
        "case": {
          "status": {
            "CANCELED": "Abgebrochen",
            "COMPLETED": "Abgeschlossen",
            "RUNNING": "In Bearbeitung",
            "SUSPENDED": "Pausiert"
          }
        },
        "work-item": {
          "status": {
            "CANCELED": "Abgebrochen",
            "COMPLETED": "Erledigt",
            "READY": "Offen",
            "SKIPPED": "Übersprungen",
            "SUSPENDED": "Pausiert"
          }
        }
      },
      "form": {
        "addRow": "Zeile hinzufügen",
        "cancel": "Abbrechen",
        "close": "Schliessen",
        "delete": "Löschen",
        "deleteRow": "Möchten Sie diese Zeile wirklich löschen?",
        "edit": "Bearbeiten",
        "error": {
          "details": "Technische Details:",
          "invalid": "Ihre Antwort konnte nicht gespeichert werden, weil die Validierung fehlgeschlagen ist. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.",
          "offline": "Ihre Antwort konnte nicht gespeichert werden, da Sie offline sind. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.",
          "technical-error": "Oh nein, auf unserer Seite ist etwas schief gelaufen. Ihre Antwort konnte nicht gespeichert werden."
        },
        "info": "Mehr Informationen",
        "navigation": {
          "next": "Weiter",
          "previous": "Zurück",
          "states": {
            "invalid": "Inkorrekt ausgefüllt",
            "unfinished": "Nicht vollständig ausgefüllt",
            "untouched": "Nicht ausgefüllt",
            "valid": "Korrekt ausgefüllt"
          }
        },
        "notification": {
          "table": {
            "add": {
              "error": "Hoppla, beim Hinzufügen des Eintrags ist etwas schief gelaufen ...",
              "success": "Eintrag wurde erfolgreich hinzugefügt."
            }
          }
        },
        "optionNotAvailable": "Diese Option ist nicht mehr verfügbar",
        "optional": "Optional",
        "power-select": {
          "options-empty": "Keine Optionen vorhanden",
          "options-loading": "Lade Optionen...",
          "placeholder-multiple": "Wählen Sie eine oder mehrere Optionen aus",
          "placeholder-single": "Wählen Sie eine Option aus",
          "reset": "Zurücksetzen",
          "search-empty": "Keine Optionen gefunden",
          "search-placeholder": "Hier tippen um zu suchen"
        },
        "save": "Speichern",
        "selectFile": "Durchsuchen...",
        "validation": {
          "blank": "Dieses Feld darf nicht leer gelassen werden",
          "deleteFailed": "Beim Löschen ist ein Fehler aufgetreten.",
          "error": "Folgende Fragen sind noch nicht korrekt ausgefüllt:",
          "format": "{errorMsg}",
          "greaterThanOrEqualTo": "Die Eingabe in diesem Feld darf nicht kleiner als {gte} sein",
          "inclusion": "\"{label}\" ist kein gültiger Wert für dieses Feld",
          "lessThanOrEqualTo": "Die Eingabe in diesem Feld darf nicht grösser als {lte} sein",
          "notAnInteger": "Bitte geben Sie eine ganze Zahl ein",
          "table": "Mindestens eine Zeile der Tabelle wurde nicht korrekt ausgefüllt",
          "tooLong": "In diesem Feld sind maximal {max} Zeichen erlaubt",
          "tooShort": "Dieses Feld muss mindestens {min} Zeichen enthalten",
          "uploadFailed": "Beim Hochladen ist ein Fehler aufgetreten."
        }
      },
      "form-builder": {
        "copy-modal": {
          "name": {
            "hint": "Original: {name}",
            "label": "Name"
          },
          "slug": {
            "hint": "Original: {slug}",
            "label": "Slug"
          },
          "submit": "Kopieren",
          "title": "\"{form}\" kopieren"
        },
        "form": {
          "active": "Aktiv",
          "all": "Alle",
          "allForms": "Alle Formulare",
          "description": "Beschreibung",
          "draft": "Entwurf",
          "empty": "Wir haben keine Formulare gefunden. Klicken Sie den Knopf im der rechten oberen Ecke um ein Formular zu erstellen!",
          "isArchived": "Archiviert",
          "isPublished": "Publiziert",
          "loadMore": "Mehr Formulare laden",
          "name": "Name",
          "new": "Neues Formular",
          "not-found": "Kein Formular mit dem Slug '{slug}' gefunden",
          "slug": "Slug",
          "widgetOverride": "Spezialelement"
        },
        "global": {
          "cancel": "Abbrechen",
          "empty-search": "Keine Resultate gefunden. Passen Sie Ihre Suche an um bessere Resultate zu erhalten.",
          "loading": "Laden",
          "not-found": {
            "home": "Zurück zum Start",
            "subtitle": "Die angefrage Seite existiert nicht!",
            "title": "Sie sehen verloren aus"
          },
          "optional": "Optional",
          "save": "Speichern",
          "search": "Suchen"
        },
        "notification": {
          "form": {
            "add-question": {
              "error": "Hoppla, beim Hinzufügen der Frage ist etwas schief gelaufen...",
              "success": "Die Frage wurde erfolgreich zu Ihrem Formular hinzugefügt!"
            },
            "create": {
              "error": "Hoppla, beim Erstellen des Formulars ist etwas schief gelaufen...",
              "success": "Ihr Formular wurde erfolgreich erstellt!"
            },
            "remove-question": {
              "error": "Hoppla, beim Entfernen der Frage ist etwas schief gelaufen...",
              "success": "Die Frage wurde erfolgreich von Ihrem Formular entfernt!"
            },
            "reorder-options": {
              "error": "Hoppla, beim Sortieren der Optionen ist etwas schief gelaufen...",
              "success": "Ihre Optionen wurde erfolgreich sortiert!"
            },
            "reorder-questions": {
              "error": "Hoppla, beim Sortieren der Fragen ist etwas schief gelaufen...",
              "success": "Ihre Fragen wurde erfolgreich sortiert!"
            },
            "save": {
              "error": "Hoppla, beim Speichern des Formulars ist etwas schief gelaufen...",
              "success": "Ihr Formular wurde erfolgreich gespeichert!"
            }
          },
          "question": {
            "save": {
              "error": "Hoppla, beim Speichern der Frage ist etwas schief gelaufen...",
              "success": "Ihre Frage wurde erfolgreich gespeichert!"
            }
          }
        },
        "options": {
          "archive": "Option archivieren (verstecken)",
          "delete": "Option löschen",
          "restore": "Option wiederherstellen"
        },
        "question": {
          "action": "Aktion",
          "actions": {
            "COMPLETE": "Abschliessen",
            "SKIP": "Überspringen"
          },
          "add": "Neue oder existierende Frage hinzufügen",
          "advancedSettings": "Erweiterte Einstellungen",
          "calcExpression": "Berechnungsformel (JEXL)",
          "color": "Farbe",
          "colors": {
            "DEFAULT": "Standard",
            "PRIMARY": "Primär",
            "SECONDARY": "Sekundär"
          },
          "columnsToDisplay": "Spalten zur Anzeige im Formular",
          "confirmText": "Bestätigungstext",
          "confirmationText": "Bestätigungstext",
          "create": "oder eine neue Frage erstellen",
          "dataSource": "Datenquelle",
          "defaultValue": "Standardwert",
          "empty": "Sie haben noch keine Fragen hinzugefügt. Drücken Sie den obenstehenden Knopf um dies zu tun!",
          "formatValidators": "Validierung",
          "general": "Allgemein",
          "hideLabel": "Label verstecken",
          "hintText": "Hinweistext",
          "infoText": "Infotext",
          "isArchived": "Archiviert",
          "isHidden": "Versteckt (JEXL)",
          "isRequired": "Pflichtfeld",
          "label": "Label",
          "loadMore": "Mehr Fragen laden",
          "markdown": "Markdown",
          "max-length": "Maximale Länge",
          "max-value": "Maximaler Wert",
          "meta": "Metainformationen",
          "min-length": "Minimale Länge",
          "min-value": "Minimaler Wert",
          "minor-info": "Das Anpassen einer Frage darf niemals die Bedeutung der Frage verändern, um die Daten konsistent zu halten.",
          "minor-info-title": "Wichtig!",
          "new": "Neue Frage",
          "no-selection": "Keine Auswahl",
          "not-found": "Keine Frage mit dem Slug '{slug}' gefunden",
          "options": "Optionen",
          "placeholder": "Platzhalter",
          "remove": "Frage entfernen",
          "rowForm": "Formular für Tabelleneinträge",
          "search": "Nach einer Frage (Label oder Slug) suchen,",
          "search-empty": "Keine Formulare gefunden",
          "search-placeholder": "Hier tippen um zu suchen",
          "showValidation": "Validierungs-Fehler anzeigen",
          "slug": "Slug",
          "staticContent": "Statischer Inhalt",
          "subForm": "Formular für Einträge",
          "supportsMarkdownPrefix": "Dieses Feld unterstützt",
          "type": "Typ",
          "type-disabled": "Der Fragetyp kann nach dem Erstellen einer Frage nicht mehr geändert werden, um korrupte Daten zu verhindern.",
          "types": {
            "ActionButtonQuestion": "Aktionsbutton",
            "CalculatedFloatQuestion": "Berechnung (Gleitkommazahl)",
            "ChoiceQuestion": "Einzelauswahl",
            "DateQuestion": "Datum",
            "DynamicChoiceQuestion": "Dynamische Einzelauswahl",
            "DynamicMultipleChoiceQuestion": "Dynamische Mehrfachauswahl",
            "FilesQuestion": "Dateien",
            "FloatQuestion": "Gleitkommazahl",
            "FormQuestion": "Formular",
            "IntegerQuestion": "Ganze Zahl",
            "MultipleChoiceQuestion": "Mehrfachauswahl",
            "StaticQuestion": "Nichtinteraktiver Inhalt",
            "TableQuestion": "Tabelle",
            "TextQuestion": "Text",
            "TextareaQuestion": "Text (mehrzeilig)"
          },
          "usage": {
            "not-published": "nicht publiziert",
            "references-heading": "Alle Verweise auf diese Frage",
            "title": "Diese Frage wird in {n,plural, =1 {<b>einem</b> Formular} other {<b>#</b> Formularen}} verwendet."
          },
          "validateOnEnter": "Validierung beim Betreten des Fensters",
          "widgetOverride": "Anzeigetyp",
          "widgetOverrides": {
            "hidden": "Versteckt",
            "number-separator": "Zahlentrennzeichen",
            "powerselect": "Power Select"
          }
        },
        "question-list": {
          "hidden": {
            "conditional": "Bedingt versteckt",
            "hidden": "Versteckt",
            "label": "Versteckt (JEXL)",
            "not-hidden": "Sichtbar"
          },
          "required": {
            "conditional": "Bedingt erforderlich",
            "label": "Erforderlich (JEXL)",
            "not-required": "Optional",
            "required": "Erforderlich"
          }
        },
        "validations": {
          "form": {
            "slug": "Ein Formular mit diesem Slug existiert bereits"
          },
          "option": {
            "slug": "Eine Option mit diesem Slug existiert bereits"
          },
          "question": {
            "slug": "Eine Frage mit diesem Slug existiert bereits"
          }
        }
      },
      "mutate-work-item": {
        "cancel": "Abbrechen",
        "complete": "Abschliessen",
        "error": {
          "cancel": "Hoppla, beim Abbrechen der Aufgabe ist etwas schief gelaufen...",
          "complete": "Hoppla, beim Abschliessen der Aufgabe ist etwas schief gelaufen...",
          "skip": "Hoppla, beim Überspringen der Aufgabe ist etwas schief gelaufen..."
        },
        "skip": "Überspringen",
        "success": {
          "cancel": "Die Aufgabe wurde erfolgreich abgebrochen!",
          "complete": "Die Aufgabe wurde erfolgreich abgeschlossen!",
          "skip": "Die Aufgabe wurde erfolgreich übersprungen!"
        }
      },
      "task-button": {
        "error": "Fehler beim Laden der Aufgabe"
      }
    },
    "components": {
      "filters": {
        "dropdown": {
          "empty": "Keine Einträge gefunden",
          "loading": "Lädt...",
          "search": "Tippen um zu suchen"
        },
        "invert": "Filter umkehren",
        "inverted": "Dieser Filter ist umgekehrt",
        "modal": {
          "close": "Filter",
          "open": "Mehr Filter",
          "title": "Filters"
        },
        "reset": "Filter zurücksetzten",
        "resetShort": "Reset"
      },
      "identity-addresses": {
        "add": "Adresse hinzufügen",
        "delete": {
          "prompt": "Wollen Sie die Adresse «{address}» wirklich löschen?",
          "success": "Adresse «{address}» wurde erfolgreich gelöscht."
        },
        "form": {
          "label": {
            "addressAddition1": "Adresszusatz 1",
            "addressAddition2": "Adresszusatz 2",
            "addressAddition3": "Adresszusatz 3",
            "country": "Land",
            "default": "Korrespondenzadresse",
            "description": "Beschreibung ({lang})",
            "poBox": "Postfach",
            "postcode": "Postleitzahl",
            "streetAndNumber": "Strasse / Nummer",
            "town": "Stadt"
          },
          "title": {
            "add": "Adresse hinzufügen",
            "edit": "Adresse bearbeiten"
          }
        },
        "list": {
          "delete": "Adresse löschen",
          "edit": "Adresse bearbeiten",
          "empty": "Bisher wurden keine Adressen erfasst."
        },
        "title": "Adressen"
      },
      "identity-emails": {
        "add": "E-Mail-Adresse hinzufügen",
        "delete": {
          "prompt": "Wollen Sie die E-Mail-Adresse «{address}» wirklich löschen?",
          "success": "E-Mail-Adresse «{address}» wurde erfolgreich gelöscht."
        },
        "form": {
          "label": {
            "default": "Korrespondenzadresse",
            "description": "Beschreibung ({lang})",
            "email": "E-Mail-Adresse"
          },
          "title": {
            "add": "E-Mail-Adresse hinzufügen",
            "edit": "E-Mail-Adresse bearbeiten"
          }
        },
        "list": {
          "delete": "E-Mail-Adresse löschen",
          "edit": "E-Mail-Adresse bearbeiten",
          "empty": "Bisher wurden keine E-Mail-Adressen erfasst.",
          "send": "E-Mail senden"
        },
        "title": "E-Mail-Adressen"
      },
      "identity-form": {
        "control": {
          "delete": "Löschen"
        },
        "delete": {
          "prompt": "Wollen Sie diese Adressse wirklich löschen?",
          "promptInfo": "Diese Adresse hat Zugriff auf {caseAmount, plural, one {# Gesuch} other {# Gesuche}}.",
          "success": "Adresse wurde erfolgreich gelöscht."
        },
        "hint": {
          "email": "Verwaltet durch KeyCloak."
        },
        "label": {
          "comment": "Kommentar",
          "email": "Primäre E-Mail-Adresse",
          "firstName": "Vorname",
          "idpId": "IDP ID",
          "isAdvisoryBoard": "Kommission/Kuratorium",
          "isExpertAssociation": "Fachgesellschaft",
          "isOrganisation": "Organisation",
          "language": "Korrespondenzsprache",
          "lastName": "Name",
          "organisationName": "Name der Organisation",
          "salutation": "Anrede",
          "title": "Titel"
        },
        "success": "Adresse «{name}» wurde erfolgreich abgespeichert."
      },
      "identity-interests": {
        "add": "Interesse hinzufügen",
        "delete": {
          "prompt": "Wollen Sie dieses Interesse «{interest}» wirklich löschen?",
          "success": "Interesse «{interest}» wurde erfolgreich gelöscht."
        },
        "disclaimer": "Mit dem Speichern erkläre ich mich einverstanden, von der SAGW Informationen oder Publikationen zu den von mir angegebenen Interessen zu erhalten und bestätige, dass ich die <a href=''https://www.sagw.ch/sagw/zur-sagw/impressum'' target=''_blank''>Hinweise zum Datenschutz</a> gelesen habe und sie akzeptiere.",
        "form": {
          "label": {
            "interest": "Interesse"
          },
          "title": {
            "add": "Interesse hinzufügen"
          }
        },
        "list": {
          "delete": "Interesse löschen",
          "empty": "Bisher wurden keine Interessen erfasst."
        },
        "title": "Interessen"
      },
      "identity-members": {
        "list": {
          "empty": "Bisher wurden keine Mitglieder erfasst."
        },
        "title": "Mitglieder"
      },
      "identity-memberships": {
        "add": "Mitgliedschaft hinzufügen",
        "delete": {
          "prompt": "Wollen Sie diese Mitgliedschaft «{membership}» wirklich löschen?",
          "success": "Mitgliedschaft «{membership}» wurde erfolgreich gelöscht."
        },
        "form": {
          "label": {
            "admin": "AdministratorIn",
            "authorized": "AdministratorIn der Organisation",
            "comment": "Bemerkung",
            "inactive": "Inaktiv",
            "nextElection": "Nächste Wiederwahl",
            "organisation": "Organisation",
            "role": "Rolle innerhalb der Organisation",
            "timeFrom": "Von",
            "timeUntil": "Bis"
          },
          "title": {
            "add": "Mitgliedschaft hinzufügen",
            "edit": "Mitgliedschaft bearbeiten"
          }
        },
        "list": {
          "delete": "Mitgliedschaft löschen",
          "edit": "Mitgliedschaft bearbeiten",
          "empty": "Bisher wurden keine Mitgliedschaften erfasst.",
          "nextElection": "Nächste Wiederwahl {date}"
        },
        "timeSlotError": "Das Bis-Datum kann nicht vor dem Von-Datum liegen.",
        "timeSlotErrorLower": "Das Von-Datum kann nicht nach dem Bis-Datum liegen.",
        "timeSlotLower": "Von",
        "timeSlotUpper": "Bis",
        "title": "Mitgliedschaften"
      },
      "identity-phone-numbers": {
        "add": "Nummer hinzufügen",
        "delete": {
          "prompt": "Wollen Sie diese Nummer «{number}» wirklich löschen?",
          "success": "Nummer «{number}» wurde erfolgreich gelöscht."
        },
        "form": {
          "label": {
            "default": "Hauptnummer",
            "description": "Beschreibung ({lang})",
            "phone": "Telefonnummer"
          },
          "title": {
            "add": "Nummer hinzufügen",
            "edit": "Nummer bearbeiten"
          }
        },
        "list": {
          "call": "Anrufen",
          "delete": "Nummer löschen",
          "edit": "Nummer bearbeiten",
          "empty": "Bisher wurden keine Telefonnummern erfasst."
        },
        "title": "Telefonnummern"
      },
      "interest-category-form": {
        "label": {
          "description": "Beschreibung",
          "public": "Public",
          "title": "Titel ({lang})"
        },
        "success": "Kategorie «{category}» wurde erfolgreich gespeichert."
      },
      "membership-role-form": {
        "label": {
          "description": "Beschreibung ({lang})",
          "title": "Titel ({lang})"
        },
        "success": "Rolle «{role}» wurde erfolgreich gespeichert."
      },
      "nav-bar": {
        "account": "Konto",
        "admin": "Administration",
        "analytics": "Auswertung",
        "documents": "Gesuche",
        "form-builder": "Form Builder",
        "form-configuration": "Form Configuration",
        "form-management": "Formularverwaltung",
        "home": "Home",
        "identities": "Adressen",
        "identity-management": "Adressverwaltung",
        "interests": "Interessen",
        "login": "Anmelden",
        "logout": "Abmelden",
        "profile": "Profil",
        "roles": "Rollen",
        "snippet": {
          "copy-error": "Während des Kopierens ist ein Fehler aufgetreten.",
          "copy-success": "Snippet wurde in die Zwischenablage kopiert.",
          "hint": "«{lang}» in die Zwischenablage kopieren.",
          "load-more": "Weitere Snippets laden"
        },
        "snippets": "Textbausteine",
        "support": "Support",
        "tasks": "Aufgaben"
      },
      "snippet-form": {
        "label": {
          "archived": "Archiviert",
          "body": "Body ({lang})",
          "title": "Titel"
        },
        "success": "Snippet «{title}» wurde erfolgreich gespeichert."
      },
      "submit-button": {
        "confirmation": "Wollen Sie das Formular definitiv absenden?",
        "error": "Ein Fehler ist passiert beim Validieren vom Dokument.",
        "title": "Absenden"
      }
    },
    "documents": {
      "accesses": {
        "add": "Zugriff gewähren",
        "duplicateEmail": "Diese E-Mail wurde schon hinzugefügt",
        "email": "E-Mail",
        "invitations": "Einladungen",
        "table": {
          "action": "Aktionen",
          "email": "E-Mail",
          "name": "Name"
        },
        "title": "Zugriffberechtigungen"
      },
      "accountingExport": "Export für Buchhaltung",
      "actions": "Aktionen",
      "asc": "Aufsteigend",
      "back": "Zurück zu Gesuche",
      "bulkEdit": {
        "action": "Dossier auswählen",
        "title": "Massenbearbeitung",
        "transfer": {
          "description": "Wählen Sie die Identität aus, welche Zugriffsberechtigungen auf die ausgewählten Gesuche bekommt. Bestehende Zugriffsberichtigungen können entzogen werden wenn Identitäts-Filter gesetzt wird und das Kontrollkästchen 'Zugriffsberechtigungen entfernen' angewählt ist.",
          "removeAssignees": "Zugriffsberechtigungen entfernen",
          "save": "Übertragen",
          "success": "Gesuche erfolgreich übertragen",
          "title": "Dossier übertragen"
        }
      },
      "circulation": {
        "add": "In Zirkulation geben",
        "finish": "Zirkulation abschliessen",
        "skip": "Zirkulation überspringen"
      },
      "complete": "Gesuch abschliessen",
      "completeSuccess": "Das Gesuch wurde abgeschlossen.",
      "completeText": "Das Gesuch kann abgeschlossen werden.",
      "createdAt": "Erstelldatum",
      "delete": "Gesuch löschen",
      "deleteConfirm": "Bestätigen",
      "deleteConfirmation": "Sind Sie sicher, dass dieses Gesuch gelöscht werden soll?",
      "deleteSuccess": "Das Gesuch wurde erfolgreich gelöscht.",
      "deleteText": "Sie können das Gesuch löschen, falls dieses nicht mehr benötigt wird.",
      "desc": "Absteigend",
      "description": "Beschreibung",
      "distributionPlan": "Verteilplan",
      "download": {
        "acknowledgement": "Eingangsbestätigung",
        "application": "Gesuch (beta)",
        "button": "Herunterladen",
        "creditApproval": "Kreditgutsprache"
      },
      "edit": "Gesuch bearbeiten",
      "editText": "Sie können das Gesuch bearbeiten und ältere Angaben ändern.",
      "empty": {
        "no-documents": "Noch keine Gesuche eröffnet.",
        "welcome": "Willkommen bei {appName}"
      },
      "fetchError": "Während des Abfragen der Gesuche ist ein Fehler aufgetreten.",
      "filters": {
        "identity": "Identität"
      },
      "general": "Information",
      "modifiedAt": "Änderungsdatum",
      "municipality": "Gemeinde",
      "new": {
        "title": "Gesuch eröffnen",
        "type": "Wählen Sie den Gesuchstyp aus"
      },
      "noActions": "Keine Aktionen verfügbar.",
      "noSubmitText": "Das Gesuch kann nicht abgesendet werden, da es unvollständig ist.",
      "number": "Referenz",
      "orderBy": "Sortieren nach",
      "pageInfo": "{length} von {total} Gesuchen zeigen",
      "redo": "Aufgabe wiederherstellen",
      "redoError": "Aufgabe wiederherstellen fehlgeschlagen",
      "redoSuccess": "Aufgabe wiederherstellt",
      "redoText": "Die vorherige Aufgabe wird wiederhergestellt.",
      "remarks": {
        "title": "Bemerkungen"
      },
      "reopen": "Gesuch wiedereröffnen",
      "reopenError": "Gesuch wiedereröffnen fehlgeschlagen",
      "reopenSuccess": "Gesuch wiedereröffnet",
      "reopenText": "Das Gesuch wird mit der letzten Aufgabe wiedereröffnet.",
      "section": "Sektion",
      "society": "Fachgesellschaft",
      "states": {
        "audit": "In Bearbeitung",
        "complete": "Abgeschlossen",
        "decision": "Entscheid",
        "revise": "Zur Überarbeitung",
        "submit": "Einreichen",
        "submit-receipts": "Belege einreichen"
      },
      "status": "Status",
      "submit": "Gesuch absenden",
      "submitSuccess": "Das Gesuch wurde erfolgreich übermittelt.",
      "submitText": "Sie können das Gesuch absenden.",
      "submittedAt": "Einreichedatum",
      "subnav": {
        "circulation": "Zirkulation",
        "circulation-answer": "Zirkulationsantwort",
        "document": "Gesuch",
        "download": "Download",
        "overview": "Übersicht",
        "work-items": "Aufgaben"
      },
      "title": "Gesuche",
      "type": "Gesuchstyp"
    },
    "form-configuration": {
      "advisoryBoardForm": "Kommission/Kuratorium",
      "expertAssociationForm": "Fachgesellschaft",
      "hiddenForm": "Versteckt",
      "saveSuccess": "Formular «{name}» wurde erfolgreich gespeichert.",
      "title": "Konfiguration"
    },
    "global": {
      "cancel": "Abbrechen",
      "continue": "Weiter",
      "empty": "Keine Einträge gefunden.",
      "languages": {
        "english": "Englisch",
        "french": "Französisch",
        "german": "Deutsch"
      },
      "loadMore": "Weitere Einträge laden",
      "moment": {
        "nextMonth": "Nächster Monat",
        "previousMonth": "Vorheriger Monat"
      },
      "openMenu": "Menü öffnen",
      "paginationInfo": "<strong>1 - {count}</strong> von {total}",
      "salutation": {
        "female": "Frau",
        "male": "Herr",
        "neutral": "Neutrale Anrede"
      },
      "save": "Speichern",
      "saveAndBack": "Speichern und zurück",
      "title": {
        "dr": "Dr.",
        "none": "Kein Titel",
        "pdDr": "PD Dr.",
        "prof": "Prof.",
        "profDr": "Prof. Dr."
      }
    },
    "identities": {
      "add": {
        "title": "Adresse hinzufügen"
      },
      "edit": {
        "title": "Adresse bearbeiten"
      },
      "index": {
        "add": "Adresse hinzufügen",
        "export": {
          "address-labels": "Adressetiketten",
          "button": "Exportieren",
          "email-only": "Nur E-mail",
          "filename": "identities-{date}",
          "memberships": "Organisationsmitglieder",
          "standard": "Standard"
        },
        "identityAmount": "{itemCount, plural, =0 {0 Identitäten} one {1 Identität} other {# Identitäten}}",
        "search": "Suchen",
        "title": "Adressen"
      }
    },
    "interests": {
      "add": {
        "title": "Interessenkategorie hinzufügen"
      },
      "edit": {
        "delete": {
          "prompt": "Wollen Sie dieses Interesse «{interest}» wirklich löschen?",
          "success": "Interesse «{interest}» wurde erfolgreich gelöscht."
        },
        "form": {
          "label": {
            "description": "Beschreibung",
            "title": "Titel ({lang})"
          },
          "title": "Interesse bearbeiten"
        },
        "list": {
          "add": "Interesse hinzufügen",
          "delete": "Interesse löschen",
          "edit": "Interesse bearbeiten",
          "empty": "Bisher wurden keine Interessen erfasst.",
          "title": "Interessen"
        },
        "title": "Interessenkategorie bearbeiten"
      },
      "index": {
        "add": "Interessenkategorie hinzufügen",
        "delete": {
          "prompt": "Wollen Sie diese Kategorie «{category}» wirklich löschen?",
          "success": "Kategorie {category} wurde erfolgreich gelöscht."
        },
        "list": {
          "delete": "Kategorie löschen",
          "edit": "Kategorie bearbeiten",
          "empty": "Bisher wurden keine Kategorien erfasst.",
          "public": "Öffentlich"
        },
        "title": "Interessen"
      }
    },
    "notfound": {
      "home": "Startseite",
      "link": "Zurück zu",
      "subtitle": "Diese Seite wurde nicht gefunden.",
      "title": "404 :-("
    },
    "profile": {
      "noNameSet": "Bitte tragen Sie ihren Vor- und Nachnamen ein.",
      "organisation": {
        "edit": "Organisation",
        "empty": "Bisher wurden keine Organisationen erfasst."
      },
      "switcher": {
        "identity": "Adresse",
        "memberships": "Mitgliedschaften"
      },
      "title": "Profil"
    },
    "roles": {
      "add": {
        "title": "Rolle hinzufügen"
      },
      "edit": {
        "title": "Rolle bearbeiten"
      },
      "index": {
        "add": "Rolle hinzufügen",
        "delete": {
          "prompt": "Wollen Sie diese Rolle «{role}» wirklich löschen?",
          "success": "Rolle «{role}» wurde erfolgreich gelöscht."
        },
        "list": {
          "delete": "Rolle löschen",
          "edit": "Rolle bearbeiten",
          "empty": "Bisher wurden keine Rollen erfasst."
        },
        "title": "Rollen"
      }
    },
    "snippets": {
      "add": {
        "title": "Snippet hinzufügen"
      },
      "edit": {
        "title": "Snippet bearbeiten"
      },
      "index": {
        "add": "Snippet hinzufügen",
        "empty": "Bisher wurden keine Snippets erfasst.",
        "search": "Suche",
        "title": "Snippets"
      }
    },
    "work-items": {
      "action": "",
      "actions": {
        "assignToMe": "Mir Zuweisen",
        "confirmation": "Haben Sie alle Belege hochgeladen?",
        "edit": "Aufgabe bearbeiten",
        "finish": "Aufgabe abschliessen",
        "highlight": "Highlight",
        "skip": "Aufgabe überspringen",
        "title": "Aktionen"
      },
      "case": "Gesuchstyp",
      "circulationAmount": "Betrag (WiMa)",
      "circulationComment": "Bemerkung",
      "circulationDecision": "Entscheid",
      "closedAt": "Abgeschlossen am",
      "closedBy": "Abgeschlossen von",
      "completedTitle": "Abgeschlossene Aufgaben",
      "createdAt": "Erstellt von",
      "createdBy": "Erstellt am",
      "description": "Beschreibung",
      "distributionPlan": "Verteilplan",
      "documentNumber": "Referenz",
      "edit": "Aufgabe bearbeiten",
      "fetchError": "Fehler beim Laden",
      "filters": {
        "active": "Aktiv",
        "all": "Alle",
        "answer": "Suchfeld",
        "closed": "Geschlossen",
        "control": "In Kontrolle",
        "distributionPlans": "Verteilplan",
        "documentNumber": "Dossiernummer",
        "expertAssociations": "Fachgesellschaft",
        "forms": "Formular",
        "new": "Neu",
        "open": "Offen",
        "order": "Sortierung",
        "own": "Eigene",
        "responsible": "Verantwortlich",
        "role": "Rolle",
        "sections": "Sektion",
        "status": "Status",
        "taskTypes": "Aufgabe",
        "type": "Typ",
        "urgent": "Dringend"
      },
      "finishSuccess": "Aufgabe abgeschlossen",
      "name": "Name",
      "rejectDefineAmountNoAdditionData": "Stelle sicher, dass noch einen Schritt zurückgegangen werden muss.",
      "responsible": "Verantwortlich",
      "saveError": "Fehler beim Speichern",
      "saveSuccess": "Aufgabe gespeichert",
      "status": "Status",
      "statuses": {
        "CANCELED": "Storniert",
        "COMPLETED": "Abgeschlossen",
        "READY": "Bereit",
        "SKIPPED": "Übersprungen",
        "SUSPENDED": "Ausgesetzt"
      },
      "task": "Aufgabe",
      "title": "Aktuelle Aufgaben"
    }
  }], ["en", {
    "caluma": {
      "analytics": {
        "back": "Back to the list",
        "cancel": "Cancel",
        "edit": {
          "add-field": "Add field",
          "add-filters": "Add filters",
          "aggregation": "Aggregation",
          "delete-field": "Delete field",
          "display-title": "Display Title",
          "display-title-placeholder": "Display Title",
          "edit-filters": "Edit {num} filters",
          "empty": "There are no fields",
          "field": "Field",
          "filter": "Filter",
          "question": "Question",
          "show-in-output": "Show in output"
        },
        "filter-modal": {
          "delete-filter": "Delete filter",
          "empty": "There are no filters",
          "filter-placeholder": "Filter...",
          "filters": "Filter"
        },
        "list": {
          "edit": "Edit",
          "list-title": "All reports"
        },
        "notification": {
          "alias-exists": "Alias already exists!",
          "create-error": "An error occurred while saving.",
          "delete-error": "An error occurred while deleting.",
          "fetch-error": "An error occurred while loading the data.",
          "field-invalid": "Please fix invalid field inputs.",
          "field-saved": "Field successfully saved.",
          "filter-exists": "Filter already exists!",
          "reorder-error": "Something went wrong while reordering the fields.",
          "reorder-success": "Fields were successfully reordered!"
        },
        "preview": {
          "export": "Export"
        },
        "report": {
          "delete": "Delete report",
          "new": "Create new report",
          "starting-object": "Starting point",
          "title": "Report title"
        },
        "save": "Save",
        "sections": {
          "create-report": "Create report",
          "fields": "Fields",
          "show-report": "Report {slug}",
          "table": "Result table"
        },
        "starting-options": {
          "cases": "Cases",
          "documents": "Documents",
          "work-items": "Work items"
        }
      },
      "caluma-query": {
        "case": {
          "status": {
            "CANCELED": "Canceled",
            "COMPLETED": "Completed",
            "RUNNING": "Pending",
            "SUSPENDED": "Suspended"
          }
        },
        "work-item": {
          "status": {
            "CANCELED": "Canceled",
            "COMPLETED": "Completed",
            "READY": "Pending",
            "SKIPPED": "Skipped",
            "SUSPENDED": "Suspended"
          }
        }
      },
      "form": {
        "addRow": "Add row",
        "cancel": "Cancel",
        "close": "Close",
        "delete": "Delete",
        "deleteRow": "Do you really want to delete this row?",
        "edit": "Edit",
        "error": {
          "details": "Technical details:",
          "invalid": "Your answer could not be saved because the validation failed. Please check your entries and try again.",
          "offline": "Your answer could not be saved because you are offline. Please check your internet connection and try again.",
          "technical-error": "Oh no, something went wrong on our side. Your answer could not be saved."
        },
        "info": "More information",
        "navigation": {
          "next": "Next",
          "previous": "Previous",
          "states": {
            "invalid": "Incorrectly filled in",
            "unfinished": "Not completely filled in",
            "untouched": "Not filled in",
            "valid": "Correctly filled in"
          }
        },
        "notification": {
          "table": {
            "add": {
              "error": "Ooops, something went wrong while adding the entry...",
              "success": "Entry was added successfully!"
            }
          }
        },
        "optionNotAvailable": "This option is not available anymore",
        "optional": "Optional",
        "power-select": {
          "options-empty": "No options available",
          "options-loading": "Loading options...",
          "placeholder-multiple": "Choose one or more options",
          "placeholder-single": "Choose one option",
          "reset": "Reset",
          "search-empty": "Search didn't match any options",
          "search-placeholder": "Type here to search options"
        },
        "save": "Save",
        "selectFile": "Browse...",
        "validation": {
          "blank": "This field can't be blank",
          "deleteFailed": "An error occured during deletion.",
          "error": "The following questions have not yet been filled in correctly:",
          "format": "{errorMsg}",
          "greaterThanOrEqualTo": "The value of this field must be greater than or equal to {gte}",
          "inclusion": "\"{label}\" is not a valid value for this field",
          "lessThanOrEqualTo": "The value of this field must be less than or equal to {lte}",
          "notAnInteger": "The value of this field must be an integer",
          "table": "At least one row of the table was not filled in correctly",
          "tooLong": "The value of this field can't be longer than {max} characters",
          "tooShort": "The value of this field can't be shorter than {min} characters",
          "uploadFailed": "An error occured during upload."
        }
      },
      "form-builder": {
        "copy-modal": {
          "name": {
            "hint": "Original: {name}",
            "label": "Name"
          },
          "slug": {
            "hint": "Original: {slug}",
            "label": "Slug"
          },
          "submit": "Copy",
          "title": "Copy \"{form}\""
        },
        "form": {
          "active": "Active",
          "all": "All",
          "allForms": "All forms",
          "description": "Description",
          "draft": "Draft",
          "empty": "We did not find any forms. Click the button in the top right corner to create a new form right now!",
          "isArchived": "Archived",
          "isPublished": "Published",
          "loadMore": "Load more forms",
          "name": "Name",
          "new": "New form",
          "not-found": "No form with slug '{slug}' found",
          "slug": "Slug",
          "widgetOverride": "Custom widget"
        },
        "global": {
          "cancel": "Cancel",
          "empty-search": "No results found. Adjust your search to get better results.",
          "loading": "Loading",
          "not-found": {
            "home": "Go to home",
            "subtitle": "The page you are looking for does not exist!",
            "title": "Looks like you're lost"
          },
          "optional": "Optional",
          "save": "Save",
          "search": "Search"
        },
        "notification": {
          "form": {
            "add-question": {
              "error": "Ooops, something went wrong while adding the question to your form...",
              "success": "The question was successfully added to your form!"
            },
            "create": {
              "error": "Ooops, something went wrong while creating the form...",
              "success": "Your form was successfully created!"
            },
            "remove-question": {
              "error": "Ooops, something went wrong while removing the question from your form...",
              "success": "The question was successfully removed from your form!"
            },
            "reorder-options": {
              "error": "Ooops, something went wrong while reordering the options...",
              "success": "Your options were successfully reordered!"
            },
            "reorder-questions": {
              "error": "Ooops, something went wrong while reordering the questions...",
              "success": "Your questions were successfully reordered!"
            },
            "save": {
              "error": "Ooops, something went wrong while saving the form...",
              "success": "Your form was successfully saved!"
            }
          },
          "question": {
            "save": {
              "error": "Ooops, something went wrong while saving the question...",
              "success": "Your question was successfully saved!"
            }
          }
        },
        "options": {
          "archive": "Archive (hide) option",
          "delete": "Delete option",
          "restore": "Restore option"
        },
        "question": {
          "action": "Action",
          "actions": {
            "COMPLETE": "Complete",
            "SKIP": "Skip"
          },
          "add": "Add a new or existing question",
          "advancedSettings": "Advanced settings",
          "calcExpression": "Calculation formula (JEXL)",
          "color": "Color",
          "colors": {
            "DEFAULT": "Default",
            "PRIMARY": "Primary",
            "SECONDARY": "Secondary"
          },
          "columnsToDisplay": "Columns to be shown in the form",
          "confirmText": "Confirm text",
          "confirmationText": "Confirmation text",
          "create": "or create a new question",
          "dataSource": "Data source",
          "defaultValue": "Default value",
          "empty": "You have not yet added a question. Press the plus button above to do so!",
          "formatValidators": "Validation",
          "general": "General",
          "hideLabel": "Hide label",
          "hintText": "Hint text",
          "infoText": "Information text",
          "isArchived": "Archived",
          "isHidden": "Hidden (JEXL)",
          "isRequired": "Required",
          "label": "Label",
          "loadMore": "Load more questions",
          "markdown": "Markdown",
          "max-length": "Maximum length",
          "max-value": "Maximum value",
          "meta": "Meta information",
          "min-length": "Minimum length",
          "min-value": "Minimum value",
          "minor-info": "Updating a question must never change the meaning to preserve data consistency.",
          "minor-info-title": "Important!",
          "new": "New question",
          "no-selection": "No selection",
          "not-found": "No question with slug '{slug}' found",
          "options": "Options",
          "placeholder": "Placeholder",
          "remove": "Remove a question",
          "rowForm": "Form to use for rows",
          "search": "Search for a question label or slug,",
          "search-empty": "Search didn't match any forms",
          "search-placeholder": "Type here to search forms",
          "showValidation": "Show validation errors",
          "slug": "Slug",
          "staticContent": "Static content",
          "subForm": "Form to use for entries",
          "supportsMarkdownPrefix": "This field supports",
          "type": "Type",
          "type-disabled": "Once created, the type can't be changed to prevent corrupt data.",
          "types": {
            "ActionButtonQuestion": "Action button",
            "CalculatedFloatQuestion": "Calculation (float)",
            "ChoiceQuestion": "Choice",
            "DateQuestion": "Date",
            "DynamicChoiceQuestion": "Dynamic choice",
            "DynamicMultipleChoiceQuestion": "Dynamic choices",
            "FilesQuestion": "Files",
            "FloatQuestion": "Float",
            "FormQuestion": "Form",
            "IntegerQuestion": "Integer",
            "MultipleChoiceQuestion": "Choices",
            "StaticQuestion": "Non-interactive content",
            "TableQuestion": "Table",
            "TextQuestion": "Text",
            "TextareaQuestion": "Textarea"
          },
          "usage": {
            "not-published": "not published",
            "references-heading": "All references of this question",
            "title": "This question is used in {n,plural, =1 {<b>another</b> form} other {<b>#</b> other forms}}"
          },
          "validateOnEnter": "Validate on entering the viewport",
          "widgetOverride": "Widget type",
          "widgetOverrides": {
            "hidden": "Hidden",
            "number-separator": "Number separator",
            "powerselect": "Power Select"
          }
        },
        "question-list": {
          "hidden": {
            "conditional": "Conditionally hidden",
            "hidden": "Hidden",
            "label": "Hidden (JEXL)",
            "not-hidden": "Visible"
          },
          "required": {
            "conditional": "Conditionally required",
            "label": "Required (JEXL)",
            "not-required": "Optional",
            "required": "Required"
          }
        },
        "validations": {
          "form": {
            "slug": "A form with this slug already exists"
          },
          "option": {
            "slug": "An option with this slug already exists"
          },
          "question": {
            "slug": "A question with this slug already exists"
          }
        }
      },
      "mutate-work-item": {
        "cancel": "Cancel",
        "complete": "Complete",
        "error": {
          "cancel": "Ooops, something went wrong while canceling the work item...",
          "complete": "Ooops, something went wrong while completing the work item...",
          "skip": "Ooops, something went wrong while skipping the work item..."
        },
        "skip": "Skip",
        "success": {
          "cancel": "Work item was successfully canceled!",
          "complete": "Work item was successfully completed!",
          "skip": "Work item was successfully skipped!"
        }
      },
      "task-button": {
        "error": "Error while fetching the work item"
      }
    },
    "components": {
      "filters": {
        "dropdown": {
          "empty": "No entries found",
          "loading": "Loading...",
          "search": "Type to search"
        },
        "invert": "Invert filter",
        "inverted": "This filter is inverted",
        "modal": {
          "close": "Filter",
          "open": "More filters",
          "title": "Filters"
        },
        "reset": "Reset filters",
        "resetShort": "Reset"
      },
      "identity-addresses": {
        "add": "Add address",
        "delete": {
          "prompt": "Do you really want to delete the address {address}?",
          "success": "Address {address} deleted successfully."
        },
        "form": {
          "label": {
            "addressAddition1": "Address addition 1",
            "addressAddition2": "Address addition 2",
            "addressAddition3": "Address addition 3",
            "country": "Country",
            "default": "This is the default address.",
            "description": "Description ({lang})",
            "poBox": "P.O. box",
            "postcode": "Postcode",
            "streetAndNumber": "Street and number",
            "town": "Town"
          },
          "title": {
            "add": "Add address",
            "edit": "Edit address"
          }
        },
        "list": {
          "delete": "Delete address",
          "edit": "Edit address",
          "empty": "No addresses yet."
        },
        "title": "Addresses"
      },
      "identity-emails": {
        "add": "Add address",
        "delete": {
          "prompt": "Do you really want to delete the address {address}?",
          "success": "Email address {address} deleted successfully."
        },
        "form": {
          "label": {
            "default": "This is the default address.",
            "description": "Description ({lang})",
            "email": "Email address"
          },
          "title": {
            "add": "Add email address",
            "edit": "Edit email address"
          }
        },
        "list": {
          "delete": "Delete address",
          "edit": "Edit address",
          "empty": "No addresses yet.",
          "send": "Send email"
        },
        "title": "Email addresses"
      },
      "identity-form": {
        "control": {
          "delete": "Delete"
        },
        "delete": {
          "prompt": "Do you really want to delete this identity?",
          "promptInfo": "This identity has access to {caseAmount, plural, one {# application} other {# applications}}.",
          "success": "Identity deleted successfully."
        },
        "hint": {
          "email": "Managed through KeyCloak."
        },
        "label": {
          "comment": "Comment",
          "email": "Main email address",
          "firstName": "First name",
          "idpId": "IDP ID",
          "isAdvisoryBoard": "Advisory board",
          "isExpertAssociation": "Expert association",
          "isOrganisation": "This is an organisation.",
          "language": "Correspondence language",
          "lastName": "Last name",
          "organisationName": "Organisation name",
          "salutation": "Salutation",
          "title": "Title"
        },
        "success": "Identity {name} saved successfully."
      },
      "identity-interests": {
        "add": "Add interest",
        "delete": {
          "prompt": "Do you really want to delete the interest {interest}?",
          "success": "Interest {interest} deleted successfully."
        },
        "disclaimer": "By saving, I agree to receive information or publications from the SAHS on the interests I have indicated and confirm that I have read and accept the <a href=''https://www.sagw.ch/sagw/zur-sagw/impressum'' target=''_blank''>information on data protection</a>.",
        "form": {
          "label": {
            "interest": "Interest"
          },
          "title": {
            "add": "Add interest"
          }
        },
        "list": {
          "delete": "Delete interest",
          "empty": "No interests yet."
        },
        "title": "Interests"
      },
      "identity-members": {
        "list": {
          "empty": "No members yet."
        },
        "title": "Members"
      },
      "identity-memberships": {
        "add": "Add membership",
        "delete": {
          "prompt": "Do you really want to delete the membership {membership}?",
          "success": "Membership {membership} deleted successfully."
        },
        "form": {
          "label": {
            "admin": "Admin",
            "authorized": "Organisation admin",
            "comment": "Comment",
            "inactive": "Inactive",
            "nextElection": "Next election",
            "organisation": "Organisation",
            "role": "Membership role",
            "timeFrom": "From",
            "timeUntil": "Until"
          },
          "title": {
            "add": "Add membership",
            "edit": "Edit membership"
          }
        },
        "list": {
          "delete": "Delete membership",
          "edit": "Edit membership",
          "empty": "No memberships yet.",
          "nextElection": "Next election {date}"
        },
        "timeSlotError": "Until date can't be before From date.",
        "timeSlotErrorLower": "From date can't be after Until date.",
        "timeSlotLower": "From",
        "timeSlotUpper": "Until",
        "title": "Memberships"
      },
      "identity-phone-numbers": {
        "add": "Add numbers",
        "delete": {
          "prompt": "Do you really want to delete the number {number}?",
          "success": "Phone number {number} deleted successfully."
        },
        "form": {
          "label": {
            "default": "This is the default number.",
            "description": "Description ({lang})",
            "phone": "Phone number"
          },
          "title": {
            "add": "Add phone number",
            "edit": "Edit phone number"
          }
        },
        "list": {
          "call": "Call",
          "delete": "Delete number",
          "edit": "Edit number",
          "empty": "No numbers yet."
        },
        "title": "Phone numbers"
      },
      "interest-category-form": {
        "label": {
          "description": "Description",
          "public": "Public",
          "title": "Title ({lang})"
        },
        "success": "Category {category} saved successfully."
      },
      "membership-role-form": {
        "label": {
          "description": "Description ({lang})",
          "title": "Title ({lang})"
        },
        "success": "Role {role} saved successfully."
      },
      "nav-bar": {
        "account": "Account",
        "admin": "Administration",
        "analytics": "Analytics",
        "documents": "Applications",
        "form-builder": "Form Builder",
        "form-configuration": "Form Configuration",
        "form-management": "Form management",
        "home": "Home",
        "identities": "Identities",
        "identity-management": "Identity management",
        "interests": "Interests",
        "login": "Login",
        "logout": "Logout",
        "profile": "Profile",
        "roles": "Roles",
        "snippet": {
          "copy-error": "There was a problem while copying the snippet",
          "copy-success": "Snippet was copied to clipboard",
          "hint": "Copy {lang} to clipboard",
          "load-more": "Load more snippets"
        },
        "snippets": "Snippets",
        "support": "Support",
        "tasks": "Tasks"
      },
      "snippet-form": {
        "label": {
          "archived": "Archived",
          "body": "Body ({lang})",
          "title": "Title"
        },
        "success": "Snippet {title} saved successfully."
      },
      "submit-button": {
        "confirmation": "Do you want to submit the form definitively?",
        "error": "An error has occured while validating the document.",
        "title": "Submit"
      }
    },
    "documents": {
      "accesses": {
        "add": "Add access",
        "duplicateEmail": "This E-Mail was already given access",
        "email": "E-Mail",
        "invitations": "Invitations",
        "table": {
          "action": "",
          "email": "E-Mail",
          "name": "Name"
        },
        "title": "Accesses"
      },
      "accountingExport": "Export for accounting",
      "actions": "Actions",
      "asc": "Asc",
      "back": "Back to applications",
      "circulation": {
        "add": "Add to circulation",
        "finish": "Finish circulation",
        "skip": "Skip circulation"
      },
      "complete": "Complete application",
      "completeSuccess": "The application has been completed.",
      "completeText": "The application can now be completed.",
      "createdAt": "Created",
      "delete": "Delete application",
      "deleteConfirm": "Confirm",
      "deleteConfirmation": "Are you sure that this application should be deleted?",
      "deleteSuccess": "The application has been deleted.",
      "deleteText": "You can delete the application if its not needed anymore.",
      "desc": "Desc",
      "description": "Description",
      "distributionPlan": "Distribution plan",
      "download": {
        "acknowledgement": "Acknowledgement of receipt",
        "application": "Application (beta)",
        "button": "Download",
        "creditApproval": "Credit approval"
      },
      "edit": "Edit document",
      "editText": "You can modify the application and change your previous data.",
      "empty": {
        "no-documents": "It looks like you do not have any application as of yet.",
        "welcome": "Welcome to {appName}"
      },
      "fetchError": "An error has occured while fetching applications.",
      "filters": {
        "identity": "Identity"
      },
      "general": "General information",
      "modifiedAt": "Last updated",
      "municipality": "Municipality",
      "new": {
        "title": "Create a new application",
        "type": "Please select the type of application"
      },
      "noActions": "No actions available",
      "noSubmitText": "You can't submit the application, it is incomplete.",
      "number": "Number",
      "orderBy": "Order by",
      "pageInfo": "Showing {length} of {total} applications",
      "redo": "Redo task",
      "redoError": "Redo task failed",
      "redoSuccess": "Task restored",
      "redoText": "The previous task will be restored.",
      "remarks": {
        "title": "Remarks"
      },
      "reopen": "Reopen application",
      "reopenError": "Reopen application failed",
      "reopenSuccess": "Application reopened",
      "reopenText": "The application with the last task will be reopened.",
      "section": "Section",
      "society": "Professional society",
      "states": {
        "audit": "Audit",
        "complete": "Completed",
        "decision": "Decision",
        "revise": "Revise",
        "submit": "Submit",
        "submit-receipts": "Submit receipts"
      },
      "status": "Status",
      "submit": "Submit application",
      "submitSuccess": "The application was successfully submitted.",
      "submitText": "You can submit the application.",
      "submittedAt": "Submitted",
      "subnav": {
        "circulation": "Circulation",
        "circulation-answer": "Circulation answer",
        "document": "Application",
        "download": "Download",
        "overview": "Overview",
        "work-items": "Tasks"
      },
      "title": "Applications",
      "type": "Type"
    },
    "form-configuration": {
      "advisoryBoardForm": "Advisory board form",
      "expertAssociationForm": "Expert association form",
      "hiddenForm": "Hidden",
      "saveSuccess": "Form {name} saved successfully",
      "title": "Configuration"
    },
    "global": {
      "cancel": "Cancel",
      "continue": "Continue",
      "empty": "No entries found",
      "languages": {
        "english": "English",
        "french": "French",
        "german": "German"
      },
      "loadMore": "Load more",
      "moment": {
        "nextMonth": "Next month",
        "previousMonth": "Previous month"
      },
      "openMenu": "Open menu",
      "paginationInfo": "<strong>1 - {count}</strong> of {total}",
      "salutation": {
        "female": "Mrs.",
        "male": "Mr.",
        "neutral": "Neutral salutation"
      },
      "save": "Save",
      "saveAndBack": "Save and Back",
      "title": {
        "dr": "Dr.",
        "none": "No title",
        "pdDr": "PD Dr.",
        "prof": "Prof.",
        "profDr": "Prof. Dr."
      }
    },
    "identities": {
      "add": {
        "title": "Add identity"
      },
      "edit": {
        "title": "Edit identity"
      },
      "index": {
        "add": "Add identity",
        "export": {
          "address-labels": "Address labels",
          "button": "Export",
          "email-only": "Email only",
          "filename": "identities-{date}",
          "memberships": "Organisation memberships",
          "standard": "Standard"
        },
        "identityAmount": "{itemCount, plural, =0 {0 Identities} one {1 Identity} other {# Identities}}",
        "search": "Search",
        "title": "Identities"
      }
    },
    "interests": {
      "add": {
        "title": "Add interest category"
      },
      "edit": {
        "delete": {
          "prompt": "Do you really want to delete the interest {interest}?",
          "success": "Interest {interest} deleted successfully."
        },
        "form": {
          "label": {
            "description": "Description",
            "title": "Title ({lang})"
          },
          "title": "Edit interest"
        },
        "list": {
          "add": "Add interest",
          "delete": "Delete interest",
          "edit": "Edit interest",
          "empty": "No interests yet.",
          "title": "Interests"
        },
        "title": "Edit interest category"
      },
      "index": {
        "add": "Add interest category",
        "delete": {
          "prompt": "Do you really want to delete the category {category}?",
          "success": "Category {category} deleted successfully."
        },
        "list": {
          "delete": "Delete category",
          "edit": "Edit category",
          "empty": "No categories yet.",
          "public": "public"
        },
        "title": "Interests"
      }
    },
    "notfound": {
      "home": "home page",
      "link": "Back to",
      "subtitle": "Page was not found",
      "title": "404"
    },
    "profile": {
      "noNameSet": "Please enter your first and last name.",
      "organisation": {
        "edit": "Organisaton",
        "empty": "No organisations yet."
      },
      "switcher": {
        "identity": "Identity",
        "memberships": "Memberships"
      },
      "title": "Profile"
    },
    "roles": {
      "add": {
        "title": "Add role"
      },
      "edit": {
        "title": "Edit role"
      },
      "index": {
        "add": "Add role",
        "delete": {
          "prompt": "Do you really want to delete the role {role}?",
          "success": "Role {role} deleted successfully."
        },
        "list": {
          "delete": "Delete role",
          "edit": "Edit role",
          "empty": "No roles yet."
        },
        "title": "Roles"
      }
    },
    "snippets": {
      "add": {
        "title": "Add snippet"
      },
      "edit": {
        "title": "Edit snippet"
      },
      "index": {
        "add": "Add snippet",
        "empty": "No snippets yet.",
        "search": "Search",
        "title": "Snippets"
      }
    },
    "work-items": {
      "action": "",
      "actions": {
        "assignToMe": "Assign to me",
        "confirmation": "Have you uploaded all the receipts?",
        "edit": "Edit task",
        "finish": "Complete task",
        "highlight": "Highlight",
        "skip": "Skip task",
        "title": "Actions"
      },
      "case": "Application",
      "circulationAmount": "Amount (WiMa)",
      "circulationComment": "Comment",
      "circulationDecision": "Decision",
      "closedAt": "Completed on",
      "closedBy": "Completed by",
      "completedTitle": "Completed tasks",
      "createdAt": "Created by",
      "createdBy": "Created on",
      "description": "Description",
      "distributionPlan": "Distribution plan",
      "documentNumber": "Number",
      "edit": "Edit task",
      "fetchError": "Error while loading tasks",
      "filters": {
        "active": "Active",
        "all": "All",
        "answer": "Finder",
        "closed": "Closed",
        "control": "Controlling",
        "distributionPlans": "Distribution plan",
        "documentNumber": "Application number",
        "expertAssociations": "Expert association",
        "forms": "Form",
        "new": "New",
        "open": "Open",
        "order": "Order",
        "own": "Own",
        "responsible": "Responsible",
        "role": "Role",
        "sections": "Section",
        "status": "Status",
        "taskTypes": "Task",
        "type": "Type",
        "urgent": "Urgent"
      },
      "finishSuccess": "Task successfully completed",
      "name": "Name",
      "rejectDefineAmountNoAdditionData": "Make sure to go an additional step back.",
      "responsible": "Responsible",
      "saveError": "Error while saving the task",
      "saveSuccess": "Task successfully saved",
      "status": "Status",
      "statuses": {
        "CANCELED": "Canceled",
        "COMPLETED": "Completed",
        "READY": "Ready",
        "SKIPPED": "Skipped",
        "SUSPENDED": "Suspended"
      },
      "task": "Task",
      "title": "Open tasks"
    }
  }], ["fr", {
    "caluma": {
      "analytics": {
        "back": "Retour à la liste",
        "cancel": "Annuler",
        "edit": {
          "add-field": "Ajouter un champ",
          "add-filters": "Ajouter des filtres",
          "aggregation": "Agrégation",
          "delete-field": "Supprimer un champ",
          "display-title": "Afficher le titre",
          "display-title-placeholder": "Afficher le titre",
          "edit-filters": "Modifier {num} de filtres",
          "empty": "Il n'y a pas de champs",
          "field": "Champ",
          "filter": "Filtre",
          "question": "Question",
          "show-in-output": "Afficher dans la sortie"
        },
        "filter-modal": {
          "delete-filter": "Supprimer le filtre",
          "empty": "Il n'y a pas de filtre",
          "filter-placeholder": "Filtre...",
          "filters": "Filtrer"
        },
        "list": {
          "edit": "Editer",
          "list-title": "Tous les rapports"
        },
        "notification": {
          "alias-exists": "L'alias existe déjà!",
          "create-error": "Une erreur s'est produite lors de la création du rapport.",
          "delete-error": "Une erreur s'est produite lors de la suppression du rapport.",
          "fetch-error": "Une erreur s'est produite lors du chargement des données.",
          "field-invalid": "Veuillez corriger les entrées de champs invalides.",
          "field-saved": "Le champ a été sauvegardé avec succès.",
          "filter-exists": "Le filtre existe déjà!",
          "reorder-error": "Quelque chose s'est mal passé lors du tri des entrées.",
          "reorder-success": "Vos entrées ont été triées avec succès!"
        },
        "preview": {
          "export": "Export"
        },
        "report": {
          "delete": "Supprimer le rapport",
          "new": "Créer un nouveau rapport",
          "starting-object": "Point de départ",
          "title": "Titre du rapport"
        },
        "save": "Enregistrer",
        "sections": {
          "create-report": "Créer un rapport",
          "fields": "Champs",
          "show-report": "Rapport {slug}",
          "table": "Table de résultats"
        },
        "starting-options": {
          "cases": "Dossiers",
          "documents": "Documents",
          "work-items": "Tâches"
        }
      },
      "caluma-query": {
        "case": {
          "status": {
            "CANCELED": "Annulé",
            "COMPLETED": "Terminé",
            "RUNNING": "En cours",
            "SUSPENDED": "En pause"
          }
        },
        "work-item": {
          "status": {
            "CANCELED": "Annulé",
            "COMPLETED": "Terminé",
            "READY": "En attente",
            "SKIPPED": "Sauté",
            "SUSPENDED": "En pause"
          }
        }
      },
      "form": {
        "addRow": "Ajouter une ligne",
        "cancel": "annuler",
        "close": "fermer",
        "delete": "supprimer",
        "deleteRow": "Voulez-vous supprimer cette ligne?",
        "edit": "modifier",
        "error": {
          "details": "Détails techniques :",
          "invalid": "Votre réponse n'a pas pu être enregistrée car la validation a échoué. Veuillez vérifier vos saisies et réessayer.",
          "offline": "Votre réponse n'a pas pu être enregistrée car vous êtes hors ligne. Veuillez vérifier votre connexion Internet et réessayer.",
          "technical-error": "Oh non, quelque chose a mal tourné de notre côté. Votre réponse n'a pas pu être sauvegardée."
        },
        "info": "Plus d'informations",
        "navigation": {
          "next": "suivante",
          "previous": "précédente",
          "states": {
            "invalid": "mal rempli",
            "unfinished": "pas complètement rempli",
            "untouched": "non rempli",
            "valid": "rempli correctement"
          }
        },
        "notification": {
          "table": {
            "add": {
              "error": "Oups, quelque chose a mal tourné lors de l'ajout de l'entrée....",
              "success": "L'entrée a été ajoutée avec succès."
            }
          }
        },
        "optionNotAvailable": "Cette option n'est plus disponible",
        "optional": "optionnelle",
        "power-select": {
          "options-empty": "Pas d'options disponibles",
          "options-loading": "chargez les options...",
          "placeholder-multiple": "Sélectionnez une ou plusieurs options",
          "placeholder-single": "Sélectionnez une option",
          "reset": "Réinitialiser",
          "search-empty": "Pas d'options trouvées",
          "search-placeholder": "Tapez ici pour rechercher"
        },
        "save": "sauvegarder",
        "selectFile": "Sélectionner...",
        "validation": {
          "blank": "Ce champ ne doit pas être laissé vide",
          "deleteFailed": "Une erreur est survenue lors de la suppression.",
          "error": "Les questions suivantes n'ont pas encore été correctement remplies :",
          "format": "{errorMsg}",
          "greaterThanOrEqualTo": "La valeur indiquée dans ce champ ne doit pas être inférieure à {gte} ",
          "inclusion": "\"{label}\" n'est pas une valeur valide pour ce champ",
          "lessThanOrEqualTo": "L'entrée dans ce champ ne doit pas être supérieure à {lte} ",
          "notAnInteger": "Veuillez entrer un nombre entier",
          "table": "Au moins une ligne du tableau n'a pas été remplie correctement",
          "tooLong": "Un maximum de {max} caractères est autorisé dans ce champ",
          "tooShort": "Ce champ doit contenir au moins {min} caractères",
          "uploadFailed": "Une erreur s'est produite pendant le téléchargement."
        }
      },
      "form-builder": {
        "copy-modal": {
          "name": {
            "hint": "Original : {name}",
            "label": "Nom"
          },
          "slug": {
            "hint": "Original : {slug}",
            "label": "Slug"
          },
          "submit": "Copier",
          "title": "Copier \"{form}\""
        },
        "form": {
          "active": "Actif",
          "all": "Tous",
          "allForms": "Tous les formulaires",
          "description": "Description",
          "draft": "Brouillon",
          "empty": "Nous n'avons trouvé aucun formulaire. Cliquez sur le bouton dans le coin supérieur droit pour créer un formulaire !",
          "isArchived": "Archivé",
          "isPublished": "Publié",
          "loadMore": "Télécharger plus de formulaires",
          "name": "Nom",
          "new": "Nouveau formulaire",
          "not-found": "Aucun formulaire trouvé avec le slug '{slug}'",
          "slug": "Slug",
          "widgetOverride": "Élément spécial"
        },
        "global": {
          "cancel": "Annuler",
          "empty-search": "Aucun résultat trouvé. Adaptez votre recherche pour obtenir de meilleurs résultats.",
          "loading": "Télécharger",
          "not-found": {
            "home": "Retour au départ",
            "subtitle": "La page demandée n'existe pas !",
            "title": "Vous avez l'air perdu"
          },
          "optional": "Facultatif",
          "save": "Enregistrer",
          "search": "Chercher"
        },
        "notification": {
          "form": {
            "add-question": {
              "error": "Oups, quelque chose s'est mal passé lors de l'ajout de la question...",
              "success": "La question a été ajoutée avec succès à votre formulaire !"
            },
            "create": {
              "error": "Oups, quelque chose s'est mal passé lors de la création du formulaire...",
              "success": "Votre formulaire a été créé avec succès !"
            },
            "remove-question": {
              "error": "Oups, quelque chose s'est mal passé lors de la suppression de la question...",
              "success": "La question a été supprimée avec succès de votre formulaire !"
            },
            "reorder-options": {
              "error": "Oups, quelque chose s'est mal passé lors du tri des options...",
              "success": "Vos options ont été triées avec succès !"
            },
            "reorder-questions": {
              "error": "Oups, quelque chose s'est mal passé lors du tri des questions...",
              "success": "Vos questions ont été triées avec succès !"
            },
            "save": {
              "error": "Oups, quelque chose s'est mal passé lors de l'enregistrement du formulaire...",
              "success": "Votre formulaire a été enregistré avec succès !"
            }
          },
          "question": {
            "save": {
              "error": "Oups, quelque chose s'est mal passé lors de l'enregistrement de la question...",
              "success": "Votre question a été enregistrée avec succès !"
            }
          }
        },
        "options": {
          "archive": "Archiver (masquer) l'option",
          "delete": "Supprimer l'option",
          "restore": "Restaurer l'option"
        },
        "question": {
          "action": "Action",
          "actions": {
            "COMPLETE": "Conclure",
            "SKIP": "Sauter"
          },
          "add": "Ajouter une question nouvelle ou existante",
          "advancedSettings": "Paramètres avancés",
          "calcExpression": "Formule de calcul (JEXL)",
          "color": "Couleur",
          "colors": {
            "DEFAULT": "Défaut",
            "PRIMARY": "Primaire",
            "SECONDARY": "Secondaire"
          },
          "columnsToDisplay": "Colonnes à afficher dans le formulaire",
          "confirmText": "Texte de confirmation",
          "confirmationText": "Texte de confirmation",
          "create": "ou créer une nouvelle question",
          "dataSource": "Source des données",
          "defaultValue": "Valeur par défaut",
          "empty": "Vous n'avez pas encore ajouté de questions. Appuyez sur le bouton ci-dessus pour le faire !",
          "formatValidators": "Validation",
          "general": "Général",
          "hideLabel": "Cacher l'étiquette",
          "hintText": "Texte indicatif",
          "infoText": "Texte d'information",
          "isArchived": "Archivé",
          "isHidden": "Caché (JEXL)",
          "isRequired": "Champ obligatoire",
          "label": "Étiquette",
          "loadMore": "Télécharger plus de questions",
          "markdown": "Markdown",
          "max-length": "Longueur maximale",
          "max-value": "Valeur maximale",
          "meta": "Méta-informations",
          "min-length": "Longueur minimale",
          "min-value": "Valeur minimale",
          "minor-info": "L'adaptation d'une question ne doit jamais modifier le sens de la question afin de maintenir la cohérence des données.",
          "minor-info-title": "Important !",
          "new": "Nouvelle question",
          "no-selection": "Aucune sélection",
          "not-found": "Aucune question trouvée avec le slug '{slug}'",
          "options": "Options",
          "placeholder": "Caractère générique",
          "remove": "Supprimer la question",
          "rowForm": "Formulaire pour les entrées de tableau",
          "search": "Chercher une question (étiquette ou slug)",
          "search-empty": "Pas de formulaires trouvés",
          "search-placeholder": "Tapez ici pour rechercher",
          "showValidation": "Afficher les erreurs de validation",
          "slug": "Slug",
          "staticContent": "Contenu statique",
          "subForm": "Formulaire pour les entrées",
          "supportsMarkdownPrefix": "Ce champ supporte",
          "type": "Type",
          "type-disabled": "Le type de question ne peut pas être modifié après la création d'une question afin d'éviter les données corrompues.",
          "types": {
            "ActionButtonQuestion": "Bouton d'action",
            "CalculatedFloatQuestion": "Calcul (nombre à virgule flottante)",
            "ChoiceQuestion": "Sélection individuel",
            "DateQuestion": "Date",
            "DynamicChoiceQuestion": "Sélection individuel dynamique",
            "DynamicMultipleChoiceQuestion": "Sélection multiple dynamique",
            "FilesQuestion": "Fichiers",
            "FloatQuestion": "Nombre à virgule flottante",
            "FormQuestion": "Formulaire",
            "IntegerQuestion": "Nombre entier",
            "MultipleChoiceQuestion": "Sélection multiple",
            "StaticQuestion": "Contenu non interactif",
            "TableQuestion": "Tableau",
            "TextQuestion": "Texte",
            "TextareaQuestion": "Texte (plusieurs lignes)"
          },
          "usage": {
            "not-published": "non publié",
            "references-heading": "Toutes les références à cette question",
            "title": "Cette question est {n,plural, =1 {utilisé sous <b>une</b> forme} other {utilisé sous <b>#</b> formes}}."
          },
          "validateOnEnter": "Validation lors de l'entrée dans la fenêtre",
          "widgetOverride": "Type d'affichage",
          "widgetOverrides": {
            "hidden": "Caché",
            "number-separator": "Séparateur de chiffres",
            "powerselect": "Power Select"
          }
        },
        "question-list": {
          "hidden": {
            "conditional": "Conditionnellement caché",
            "hidden": "Caché",
            "label": "Caché (JEXL)",
            "not-hidden": "Visible"
          },
          "required": {
            "conditional": "Conditionnellement obligatoire",
            "label": "Obligatoire (JEXL)",
            "not-required": "Facultatif",
            "required": "Obligatoire"
          }
        },
        "validations": {
          "form": {
            "slug": "Un formulaire avec ce slug existe déjà"
          },
          "option": {
            "slug": "Une option avec ce slug existe déjà"
          },
          "question": {
            "slug": "Une question avec ce slug existe déjà"
          }
        }
      },
      "mutate-work-item": {
        "cancel": "Annuler",
        "complete": "Terminer",
        "error": {
          "cancel": "Oups, quelque chose s'est mal passé lors de l'annulation de la tâche",
          "complete": "Oups, quelque chose s'est mal passé lors de la clôture de la tâche...",
          "skip": "Oups, quelque chose s'est mal passé lors du saut de la tâche..."
        },
        "skip": "Sauter",
        "success": {
          "cancel": "La tâche a été annulée avec succès !",
          "complete": "La tâche a été terminée avec succès !",
          "skip": "La tâche a été sautée avec succès !"
        }
      },
      "task-button": {
        "error": "Erreur lors du chargement de la tâche"
      }
    },
    "components": {
      "filters": {
        "dropdown": {
          "empty": "Aucune entrée trouvée",
          "loading": "Chargement...",
          "search": "Tapez pour rechercher"
        },
        "invert": "Filtre inversé",
        "inverted": "Ce filtre est inversé",
        "modal": {
          "close": "Filtre",
          "open": "Plus de filtres",
          "title": "Filtres"
        },
        "reset": "Réinitialisation",
        "resetShort": "Reset"
      },
      "identity-addresses": {
        "add": "Ajouter une adresse",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer l'adresse «{address}»?",
          "success": "L'adresse «{address}» a été supprimée avec succès."
        },
        "form": {
          "label": {
            "addressAddition1": "Complément d'adresse 1",
            "addressAddition2": "Complément d'adresse 2",
            "addressAddition3": "Complément d'adresse 3",
            "country": "Pays",
            "default": "Adresse de correspondance",
            "description": "Description ({lang})",
            "poBox": "Case postale",
            "postcode": "Code postal",
            "streetAndNumber": "Rue / Numéro",
            "town": "Ville"
          },
          "title": {
            "add": "Ajouter une adresse",
            "edit": "Modifier une adresse"
          }
        },
        "list": {
          "delete": "Effacer une adresse",
          "edit": "Modifier une adresse",
          "empty": "Jusqu'à présent aucune adresse n'a été enregistrée."
        },
        "title": "Adresses"
      },
      "identity-emails": {
        "add": "Ajouter une adresse électronique",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer l'adresse électronique «{address}»?",
          "success": "L'adresse électronique «{address}» a été supprimée avec succès."
        },
        "form": {
          "label": {
            "default": "Adresse de correspondance",
            "description": "Description ({lang})",
            "email": "Adresse électronique"
          },
          "title": {
            "add": "Ajouter une adresse électronique",
            "edit": "Modifier une adresse électronique"
          }
        },
        "list": {
          "delete": "Effacer une adresse électronique",
          "edit": "Modifier une adresse électronique",
          "empty": "Jusqu'à présent aucune adresse électronique n'a été saisie.",
          "send": "Envoyer un courriel"
        },
        "title": "Adresses électroniques"
      },
      "identity-form": {
        "control": {
          "delete": "Supprimer"
        },
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer cette adresse?",
          "promptInfo": "Cette identité a accès à {caseAmount, plural, one {# requête} other {# requêtes}}.",
          "success": "L'adresse a été supprimée avec succès."
        },
        "hint": {
          "email": "Géré par KeyCloak."
        },
        "label": {
          "comment": "Commentaire",
          "email": "Adresse électronique principale",
          "firstName": "Prénom",
          "idpId": "IDP ID",
          "isAdvisoryBoard": "Commission/Curatorium",
          "isExpertAssociation": "Société membre",
          "isOrganisation": "Institution",
          "language": "Langue de correspondance",
          "lastName": "Nom",
          "organisationName": "Nom de l'institution",
          "salutation": "Civilité",
          "title": "Titre"
        },
        "success": "L'adresse «{name}» a été sauvegardée avec succès."
      },
      "identity-interests": {
        "add": "Ajouter un intérêt",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer cet intérêt «{interest}»?",
          "success": "L'intérêt «{interest}» a été supprimé avec succès."
        },
        "disclaimer": "En enregistrant, je donne mon accord pour recevoir de l’ASSH des informations ou des publications en lien avec les intérêts que j’ai indiqués et je confirme avoir lu et accepté les indications relatives à la <a href=''https://www.sagw.ch/fr/assh/a-propos/impressum'' target=''_blank''>protection des données</a>.",
        "form": {
          "label": {
            "interest": "Intérêts"
          },
          "title": {
            "add": "Ajouter un intérêt"
          }
        },
        "list": {
          "delete": "Supprimer un intérêt",
          "empty": "Jusqu'à présent aucun intérêt n'a été enregistré."
        },
        "title": "Intérêts"
      },
      "identity-members": {
        "list": {
          "empty": "Jusqu'à présent aucun membre n'a été enregistré."
        },
        "title": "Membres"
      },
      "identity-memberships": {
        "add": "Ajouter une affiliation",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer cette affiliation «{membership}»?",
          "success": "L'affiliation «{membership}» a été supprimée avec succès."
        },
        "form": {
          "label": {
            "admin": "Administrateur·trice",
            "authorized": "Administrateur·trice de l'institution",
            "comment": "Remarque",
            "inactive": "Inactif·ve",
            "nextElection": "Prochaine réélection",
            "organisation": "Institution",
            "role": "Rôle au sein de l'institution",
            "timeFrom": "Depuis",
            "timeUntil": "Jusqu'à"
          },
          "title": {
            "add": "Ajouter une affiliation",
            "edit": "Modifier l'affiliation"
          }
        },
        "list": {
          "delete": "Supprimer l'affiliation",
          "edit": "Modifier l'affiliation",
          "empty": "Jusqu'à présent aucune affiliation n'a été enregistrée.",
          "nextElection": "Prochaine réélection {date}"
        },
        "timeSlotError": "La date de fin ne peut pas être antérieure à la date de début.",
        "timeSlotErrorLower": "La date de début ne peut pas être postérieure à la date de fin.",
        "timeSlotLower": "Depuis",
        "timeSlotUpper": "Jusqu'à",
        "title": "Affiliations"
      },
      "identity-phone-numbers": {
        "add": "Ajouter un numéro",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer ce numéro «{number}»?",
          "success": "Le numéro «{number}» a été supprimé avec succès."
        },
        "form": {
          "label": {
            "default": "Numéro principal",
            "description": "Description ({lang})",
            "phone": "Numéro de téléphone"
          },
          "title": {
            "add": "Ajouter un numéro",
            "edit": "Modifier un numéro"
          }
        },
        "list": {
          "call": "Appeler",
          "delete": "Supprimer un numéro",
          "edit": "Modifier un numéro",
          "empty": "Jusqu'à présent aucun numéro n'a été enregistré."
        },
        "title": "Numéros de téléphone"
      },
      "interest-category-form": {
        "label": {
          "description": "Description",
          "public": "Public",
          "title": "Titre ({lang})"
        },
        "success": "La catégorie «{category}» a été enregistrée avec succès."
      },
      "membership-role-form": {
        "label": {
          "description": "Description ({lang})",
          "title": "Titre ({lang})"
        },
        "success": "Le rôle «{role}» a été enregistré avec succès."
      },
      "nav-bar": {
        "account": "Compte",
        "admin": "Administration",
        "analytics": "Évaluation",
        "documents": "Requêtes",
        "form-builder": "Form Builder",
        "form-configuration": "Form Configuration",
        "form-management": "Gestion du formulaire",
        "home": "Accueil",
        "identities": "Adresses",
        "identity-management": "Gestion des adresses",
        "interests": "Intérêts",
        "login": "Se connecter",
        "logout": "Se déconnecter",
        "profile": "Profil",
        "roles": "Rôles",
        "snippet": {
          "copy-error": "Une erreur s'est produite pendant la copie.",
          "copy-success": "Le bloc de texte a été copié dans le presse-papier.",
          "hint": "Copier «{lang}» dans le presse-papier.",
          "load-more": "Charger d'autres blocs de texte"
        },
        "snippets": "Blocs de texte",
        "support": "Aide",
        "tasks": "Tâches"
      },
      "snippet-form": {
        "label": {
          "archived": "Archivé",
          "body": "Body ({lang})",
          "title": "Titre"
        },
        "success": "L'extrait «{title}» a été sauvegardé avec succès."
      },
      "submit-button": {
        "confirmation": "Voulez-vous envoyer définitivement le formulaire?",
        "error": "Une erreur s'est produite lors de la validation du document.",
        "title": "Soumettre"
      }
    },
    "documents": {
      "accesses": {
        "add": "Autoriser l'accès",
        "duplicateEmail": "Ce courriel a déjà été donné accès",
        "email": "E-Mail",
        "invitations": "Invitations",
        "table": {
          "action": "",
          "email": "E-Mail",
          "name": "Nom"
        },
        "title": "Accès"
      },
      "accountingExport": "Exportation pour la comptabilité",
      "actions": "Actions",
      "asc": "Ordre croissant",
      "back": "Retour à Requêtes",
      "circulation": {
        "add": "Mettre en circulation",
        "finish": "Terminer la circulation",
        "skip": "Sauter l'étape de la circulation"
      },
      "complete": "Compléter la requête",
      "completeSuccess": "La requête a été complétée.",
      "completeText": "La requête peut maintenant être complétée.",
      "createdAt": "Date de création",
      "delete": "Supprimer la requête",
      "deleteConfirm": "Confirmer",
      "deleteConfirmation": "Êtes-vous sûr que cette requête doit être supprimée?",
      "deleteSuccess": "La requête a été effacée avec succès.",
      "deleteText": "Vous pouvez supprimer la requête au cas où elle ne serait plus nécessaire.",
      "desc": "Ordre décroissant",
      "description": "Description",
      "distributionPlan": "Plan de distribution",
      "download": {
        "acknowledgement": "Accusé de réception",
        "application": "Requête (beta)",
        "button": "Télécharger",
        "creditApproval": "Accord de crédit"
      },
      "edit": "Saisir / modifier la requête",
      "editText": "Vous pouvez saisir / modifier la requête et changer les anciennes données.",
      "empty": {
        "no-documents": "Aucune requête n'a encore été ouverte.",
        "welcome": "Bienvenue {appName}"
      },
      "fetchError": "Une erreur s'est produite lors de la récupération des documents.",
      "filters": {
        "identity": "Identité"
      },
      "general": "Information",
      "modifiedAt": "Date de modification",
      "municipality": "Commune",
      "new": {
        "title": "Ouvrir une nouvelle requête",
        "type": "Choisissez le type de requête"
      },
      "noActions": "Aucune action n'est disponible.",
      "noSubmitText": "La requête ne peut pas être envoyée car elle est incomplète.",
      "number": "Référence",
      "orderBy": "Trier selon",
      "pageInfo": "Montrer {length} de {total} requêtes",
      "redo": "Tâche à refaire",
      "redoError": "La tâche de redémarrage a échoué",
      "redoSuccess": "Tâche restaurée",
      "redoText": "La tâche précédente sera rétablie.",
      "remarks": {
        "title": "Remarques"
      },
      "reopen": "Rouvrir l'application",
      "reopenError": "La réouverture de l'application a échoué",
      "reopenSuccess": "Application réouverte",
      "reopenText": "L'application avec la dernière tâche sera rouverte.",
      "section": "Segment",
      "society": "Société spécialisée",
      "states": {
        "audit": "En traitement",
        "complete": "Bouclée",
        "decision": "Décision",
        "revise": "Pour modification",
        "submit": "Soumettre la requête",
        "submit-receipts": "Joindre des annexes"
      },
      "status": "Statut",
      "submit": "Envoyer la requête",
      "submitSuccess": "La requête a été soumise avec succès.",
      "submitText": "Vous pouvez envoyer la requête.",
      "submittedAt": "Date de soumission",
      "subnav": {
        "circulation": "Circulation",
        "circulation-answer": "Réponse circulation",
        "document": "Requête",
        "download": "Télécharger",
        "overview": "Vue d'ensemble",
        "work-items": "Tâches"
      },
      "title": "Requêtes",
      "type": "Type de requête"
    },
    "form-configuration": {
      "advisoryBoardForm": "Commission/Curatorium",
      "expertAssociationForm": "Société membre",
      "hiddenForm": "Caché",
      "saveSuccess": "Le formulaire «{name}» a été enregistré avec succès.",
      "title": "Configuration"
    },
    "global": {
      "cancel": "Annuler",
      "continue": "Suivant",
      "empty": "Aucune entrée trouvée.",
      "languages": {
        "english": "English",
        "french": "Français",
        "german": "Deutsch"
      },
      "loadMore": "Ajouter d'autres entrées",
      "moment": {
        "nextMonth": "Mois prochain",
        "previousMonth": "Mois précédent"
      },
      "openMenu": "Ouvrir le menu",
      "paginationInfo": "<strong>1 - {count}</strong> von {total}",
      "salutation": {
        "female": "Madame",
        "male": "Monsieur",
        "neutral": "Formulation neutre"
      },
      "save": "Sauvegarder",
      "saveAndBack": "Sauvegarde et retour",
      "title": {
        "dr": "Dr",
        "none": "Aucun titre",
        "pdDr": "PD Dr",
        "prof": "Prof.",
        "profDr": "Prof. Dr"
      }
    },
    "identities": {
      "add": {
        "title": "Ajouter une adresse"
      },
      "edit": {
        "title": "Modifier une adresse"
      },
      "index": {
        "add": "Ajouter une adresse",
        "export": {
          "address-labels": "Etiquettes d'adresses",
          "button": "Exporter",
          "email-only": "Adresse électronique uniquement",
          "filename": "Identités-{date}",
          "memberships": "Membres de l'organisation",
          "standard": "Standard"
        },
        "identityAmount": "{itemCount, plural, =0 {0 Identités} one {1 Identité} other {# Identités}}",
        "search": "Chercher",
        "title": "Adresses"
      }
    },
    "interests": {
      "add": {
        "title": "Ajouter une catégorie d'intérêts"
      },
      "edit": {
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer l'intérêt «{interest}»?",
          "success": "L'intérêt «{interest}» a été supprimé avec succès."
        },
        "form": {
          "label": {
            "description": "Description",
            "title": "Titre ({lang})"
          },
          "title": "Modifier un intérêt"
        },
        "list": {
          "add": "Ajouter un intérêt",
          "delete": "Supprimer un intérêt",
          "edit": "Modifier un intérêt",
          "empty": "Jusqu'à présent aucun intérêt n'a été saisi.",
          "title": "Intérêts"
        },
        "title": "Modifier une catégorie d'intérêts"
      },
      "index": {
        "add": "Ajouter une catégorie d'intérêts",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer la catégorie «{category}»?",
          "success": "La catégorie {category} a été supprimée avec succès."
        },
        "list": {
          "delete": "Supprimer la catégorie",
          "edit": "Modifier la catégorie",
          "empty": "Jusqu'à présent aucune catégorie n'a été saisie.",
          "public": "Public"
        },
        "title": "Intérêts"
      }
    },
    "notfound": {
      "home": "Page d'accueil",
      "link": "Retour vers",
      "subtitle": "Cette page est introuvable.",
      "title": "404 :-("
    },
    "profile": {
      "noNameSet": "Veuillez inscrire votre prénom et votre nom de famille.",
      "organisation": {
        "edit": "Institution",
        "empty": "Jusqu'à présent aucune institution n'a été saisie."
      },
      "switcher": {
        "identity": "Adresse",
        "memberships": "Affiliations"
      },
      "title": "Profil"
    },
    "roles": {
      "add": {
        "title": "Ajouter un rôle"
      },
      "edit": {
        "title": "Modifier un rôle"
      },
      "index": {
        "add": "Ajouter un rôle",
        "delete": {
          "prompt": "Souhaitez-vous vraiment supprimer le rôle «{role}»?",
          "success": "Le rôle «{role}» a été supprimé avec succès."
        },
        "list": {
          "delete": "Supprimer un rôle",
          "edit": "Modifier un rôle",
          "empty": "Jusqu'à présent aucun rôle n'a été saisi."
        },
        "title": "Rôles"
      }
    },
    "snippets": {
      "add": {
        "title": "Ajouter un bloc de texte"
      },
      "edit": {
        "title": "Modifier un bloc de texte"
      },
      "index": {
        "add": "Ajouter un bloc de texte",
        "empty": "Jusqu'à présent aucun bloc de texte n'a été saisi.",
        "search": "Recherche",
        "title": "Blocs de texte"
      }
    },
    "work-items": {
      "action": "",
      "actions": {
        "assignToMe": "Attribuer à moi",
        "confirmation": "Avez-vous téléchargé tous les justificatifs?",
        "edit": "Modifier la tâche",
        "finish": "Terminer la tâche",
        "highlight": "Highlight",
        "skip": "Ignorer la tâche",
        "title": "Action"
      },
      "case": "Type de requête",
      "circulationAmount": "Somme (WiMa)",
      "circulationComment": "Remarque",
      "circulationDecision": "Décision",
      "closedAt": "Terminé le",
      "closedBy": "Terminé par",
      "completedTitle": "Tâches terminées",
      "createdAt": "Créé par",
      "createdBy": "Créé le",
      "description": "Description",
      "distributionPlan": "Plan de distribution",
      "documentNumber": "Référence",
      "edit": "Modifier la tâche",
      "fetchError": "Erreur lors du téléchargement",
      "filters": {
        "active": "Actif",
        "all": "Tous",
        "answer": "Champ de recherche",
        "closed": "Terminé",
        "control": "En contrôle",
        "distributionPlans": "Plan de distribution",
        "documentNumber": "Numéro du document",
        "expertAssociations": "Société membre",
        "forms": "Formulaire",
        "new": "Nouveau",
        "open": "Ouvert",
        "order": "Tri",
        "own": "Propre",
        "responsible": "Responsable",
        "role": "Rôle",
        "sections": "Section",
        "status": "Statut",
        "taskTypes": "Tâche",
        "type": "Type",
        "urgent": "Urgent"
      },
      "finishSuccess": "Tâche terminée",
      "name": "Nom",
      "rejectDefineAmountNoAdditionData": "Assure-toi qu'il y a encore un pas à faire en arrière.",
      "responsible": "Responsable",
      "saveError": "Erreur lors de la sauvegarde",
      "saveSuccess": "Tâche enregistrée",
      "status": "Statut",
      "statuses": {
        "CANCELED": "Annulé",
        "COMPLETED": "Terminé",
        "READY": "Prêt",
        "SKIPPED": "Ignoré",
        "SUSPENDED": "Suspendu"
      },
      "task": "Tâche",
      "title": "Tâches actuelles"
    }
  }]];
});